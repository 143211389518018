import React from "react";

class Footer extends React.Component {
  render() {
    return (
        <footer class="footer">
			<div class="l-center">
				<div class="row footer-top">
					<nav class="s-fl-right">
						<ol class="uilist">
							<li class="first"><a href="/sitemap/">Sitemap</a></li>
							<li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
							<li><a href="/privacybeleid/">Privacybeleid</a></li>
							<li><a href="/cookiebeleid/">Cookiebeleid</a></li>
							<li class="last"><a href="/contact/">Contact</a></li>

						</ol>
					</nav>
						<p class="">© Johnson &amp; Johnson Consumer NV 2022-2024. BE-BR-2200005.
					<br />Laatst bijgewerkt: april, 2022</p>
<a href="#" id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>

					</div>
					<div class="row footer-middle">
						<p>Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. De inhoud van de site is bestemd voor bezoekers uit België en G.H. Luxemburg.
						</p>
					</div>
			</div>
			<div class="row row-fixed">
				<div class="l-center">
					<p>Bronchosedal® Dextromethorphan (dextromethorfanhydrobromide) is een geneesmiddel. Niet gebruiken bij zwangerschap, borstvoeding en kinderen jonger dan 6 jaar. Niet langer dan 5 dagen gebruiken zonder geneeskundig advies.
						<br />Bronchosedal® Dextromethorphan Unidose (dextromethorfanhydrobromide) is een geneesmiddel. Niet gebruiken bij zwangerschap, borstvoeding en kinderen jonger dan 12 jaar. Niet langer dan 5 dagen gebruiken zonder geneeskundig advies.
						<br />Bronchosedal® Mucus Honing &amp; Citroen (guaifenesine) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 7 dagen gebruiken zonder geneeskundig advies.
						<br /> Bronchosedal® Mucus Menthol (guaifenesine) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 7 dagen gebruiken zonder geneeskundig advies.
						<br />
						<strong>Lees aandachtig de
							<a href="/bijsluiters/"> bijsluiter</a>.
							Vraag advies aan uw arts of apotheker. © 2022 Johnson &amp; Johnson Consumer NV BE-BR-2200005
						</strong>
						</p>
				</div>
			</div>
		</footer>
);
  }
}

export default Footer;
